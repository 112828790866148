export const emergency = {
  groups: [
    {
      id: 'phone',
      title: 'Telephone Services',
      icon: 'fas fa-phone',
      items: [
        {
          title: 'Lifeline',
          phone: '13 11 14',
          description: 'A national charity providing all Australians experiencing a personal crisis with access to 24-hour crisis support.',
        },
        {
          title: 'Suicide Callback Service',
          phone: '1300 659 467',
          description: 'A nationwide service providing 24/7 telephone and online counselling to people affected by suicide.',
        },
        {
          title: 'Kids Helpline',
          phone: '1800 55 1800',
          description: 'Kids Helpline is a free 24 hour counselling service for Australian kids and young people aged 5-25 years.',
        },
        {
          title: 'Beyond Blue',
          phone: '1300 22 4636',
          description: 'Beyond Blue works with the community to improve mental health and prevent suicide, so that all people in Australia can achieve their best possible mental health.',
        },
        {
          title: 'QLife',
          phone: '1800 184 527',
          description: 'QLife provides Australia-wide anonymous, LGBTI peer support and referral for people wanting to talk about a range of issues including sexuality, identity, gender, bodies, feelings or relationships.',
        },
        {
          title: 'Mensline Australia',
          phone: '1300 78 99 78',
          description: 'A telephone and online counselling service offering support for Australian men anywhere, anytime',
        },
        {
          title: 'Overwatch Australia',
          phone: '1800 699 2824',
          description: 'A not for profit organisation tasked with reaching out and assisting former Australian Defence Force (ADF) members.',
        },
        {
          title: 'DV Connect Womensline',
          phone: '1800 811 811',
          description: 'Call Womensline 24 hours, 7 days if you are experiencing domestic, family, or sexual violence.',
        },
        {
          title: '1800 RESPECT',
          phone: '1800 737 732',
          description: '1800RESPECT is a counselling, information and referral service for all Australians.',
        },
        {
          title: 'Elder Abuse Prevention Unit',
          phone: '1300 651 192',
          description: 'A first port-of-call for support, referrals and information for elder abuse.',
        },
        {
          title: 'ADIS 24/7 Alcohol & Drug Support',
          phone: '1800 177 833',
          description: '24/7 support for people in Queensland with alcohol and other drug concerns.',
        },
        {
          title: 'Gambling Helpline',
          phone: '1800 858 858',
          description: 'A free, confidential telephone help service which operates 24 hours a day, 7 days a week.',
        },
      ],
    },
    {
      id: 'chat',
      title: 'Online Chat Services',
      icon: 'fas fa-comments',
      items: [
        {
          title: 'Suicide Callback Service',
          chatUrl: 'https://www.suicidecallbackservice.org.au/phone-and-online-counselling/suicide-call-back-service-online-counselling/',
          description: 'A nationwide service providing 24/7 telephone and online counselling to people affected by suicide.',
        },
        {
          title: 'Lifeline',
          chatUrl: 'https://www.lifeline.org.au/get-help/online-services/crisis-chat',
          description: 'A national charity providing all Australians experiencing a personal crisis with access to 24-hour crisis support.',
        },
        {
          title: 'Headspace',
          chatUrl: 'https://headspace.org.au/eheadspace/connect-with-a-clinician/',
          description: 'headspace is a free and confidential service for young people aged 12-25 years.',
        },
        {
          title: 'Head to Sam the Chatbot',
          chatUrl: 'https://headtohealth.gov.au/sam-the-chatbot/',
          description: 'Sam the Chatbot is a virtual assistant that provides you with information and lets you know about services available to you.',
        },
        {
          title: 'QLife',
          chatUrl: 'https://www.qlife.org.au/resources/chat',
          description: 'QLife provides Australia-wide anonymous, LGBTI peer support and referral for people wanting to talk about a range of issues including sexuality, identity, gender, bodies, feelings or relationships.',
        },
        {
          title: 'Beyond Blue Chat',
          chatUrl: 'https://online.beyondblue.org.au/#/chat/start',
          description: 'Beyond Blue works with the community to improve mental health and prevent suicide, so that all people in Australia can achieve their best possible mental health.',
        },
        {
          title: 'Counselling ONLINE – Alcohol & Drug Support',
          chatUrl: 'https://www.counsellingonline.org.au/find-support/chat-to-a-counsellor',
          description: 'Counselling Online is a free and confidential service that provides 24/7 support to people across Australia affected by alcohol or drug use.',
        },
      ],
    },
  ],
}
