// import logo from './assets/images/full_logo@0.5x.png'

import logo from './assets/images/mindcare-logo.svg'
import mapMarker from './assets/images/marker-mindcare.png'

import shared from '../shared/assets'

const { cie, headerIcons, healthengine } = shared.defaults

import '@fontsource/nunito/300.css'
import '@fontsource/nunito/400.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'

export const assets = {
  logo,
  // logoSmall,
  cie,
  headerIcons,
  mapMarker,
  healthengine,
  // mapMarkers,
}
